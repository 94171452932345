import { handleApiSuccess } from './utils';

export function makeCatalog(instance) {
  const getStatesRM = () =>
    instance.get('MH_GetEstados').then(handleApiSuccess);

  const getTownShipsByStateRM = (stateValue) =>
    instance
      .get(`MH_GetMunicipios_x_idEstado?idEstado=${stateValue}`)
      .then(handleApiSuccess);

  const getSpecialitiesByLocationRM = (stateValue, townShipValue) =>
    instance
      .get(
        `MH_GetEspecialidades_x_idMunicipio?idEstado=${stateValue}&idMunicipio=${townShipValue}`
      )
      .then(handleApiSuccess);

  const getStatesTDC = () =>
    instance.get('TDC_GetEstados').then(handleApiSuccess);

  const getTownShipsByStateTDC = (stateValue) =>
    instance
      .get(`TDC_GetMunicipios_x_idEstado?idEstado=${stateValue}`)
      .then(handleApiSuccess);

  const getSpecialitiesByLocationTDC = (stateValue, townShipValue) =>
    instance
      .get(
        `TDC_GetEspecialidades_x_idMunicipio?idEstado=${stateValue}&idMunicipio=${townShipValue}`
      )
      .then(handleApiSuccess);

  return {
    getStatesRM,
    getTownShipsByStateRM,
    getSpecialitiesByLocationRM,
    getStatesTDC,
    getTownShipsByStateTDC,
    getSpecialitiesByLocationTDC,
  };
}
export default makeCatalog;
