import React from 'react';
import { useHistory } from 'react-router';
import "./Login.scss";

const Login = () => {
  const history = useHistory();
  return (
    <div className="container_login">
      <div className="form_login">
          <h1>Bienvenido</h1>
         <button onClick={() => history.push('/')}>INGRESAR</button>
      </div>
    </div>
  );
};

export default Login;