import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './rootReducer';

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['auth', 'catalog', 'filters', 'discounts'],
};
const pReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};
const composeEnhancers = (typeof window !== 'undefined'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  || compose;

const middlewares = {
  production: () => applyMiddleware(thunk, promise),
  development: () => composeEnhancers(
    applyMiddleware(thunk, promise),
  ),
};

const store = createStore(
  pReducer,
  initialState,
  middlewares[process.env.NODE_ENV]() || middlewares.development(),
);

const persistor = persistStore(store);

export { persistor, store };
