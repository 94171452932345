import { combineReducers } from 'redux';
import authReducers from '../reducers/auth/authReducers';
import catalogReducers from '../reducers/catalog/catalogReducers';
import filtersReducers from '../reducers/filters/filtersReducers';
import discountsReducers from '../reducers/discounts/discountsReducers';
import { LOG_OUT } from '../constants/auth';

const appReducer = combineReducers({
  auth: authReducers,
  catalog: catalogReducers,
  filters: filtersReducers,
  discounts: discountsReducers
});

const rootReducer = (state, action) => appReducer(
  (action.type === `${LOG_OUT}_PENDING` ? undefined : state), action,
);
export default rootReducer;
