import typeToReducer from 'type-to-reducer';

import {
  VERIFY_LOGIN,
  LOGIN,
  LOG_OUT,
  ACK_ERROR,
} from '../../constants/auth';

const initialState = {
  isLoading: false,
  user: {},
  error: {
    status: '',
    message: '',
  },
};

const makeAuthentication = (state, { payload }) => {
  return ({
    ...state,
    user: {
      payload
    },
    isLoading: false,
  });
};

const reducers = {
  [VERIFY_LOGIN]: {
    PENDING: (state) => ({ ...state, isLoading: true, error: initialState.error }),
    FULFILLED:  (state) => ({ ...state, isLoading: false }),
    REJECTED: (state) => ({ ...state, isLoading: false }),
  },
  [LOGIN]: {
    PENDING: (state) => ({ ...state, isLoading: true, error: initialState.error }),
    FULFILLED: makeAuthentication,
    REJECTED: (state, { payload }) => ({ ...state, isLoading: false, error: payload }),
  },
  [LOG_OUT]: {
    PENDING: (state) => ({
      ...state, isLoading: true, user: initialState.user, error: initialState.error,
    }),
    FULFILLED: (state) => ({ ...state, isLoading: false }),
    REJECTED: (state, { payload }) => ({ ...state, isLoading: false, error: payload }),
  },
  [ACK_ERROR]: (state) => ({ ...state, error: initialState.error }),
};

export default typeToReducer(reducers, initialState);
