import * as Cookies from 'js-cookie'
const { REACT_APP_URL } = process.env;

export const getItem = (itemName, params) => {
  let result = null
  try {
    result = Cookies.get(itemName, params)
  } catch (e) {
    console.warn(e)
  }
  return result
}

export const setItem = (itemName, value, params) => {
  try {
    Cookies.set(itemName, value, params)
  } catch (e) {
    console.warn(e)
  }
}

export const deleteItem = (itemName) => {
  try {
    Cookies.remove(itemName, REACT_APP_URL);
  } catch (e) {
    console.warn('Error during cookie deletion');
    console.warn(e)
  }
  console.warn('Cookie deletion success');
}

export const getToken = () => getItem('token', REACT_APP_URL)

export const setToken = (value) => {
  setItem('token', value, REACT_APP_URL)
}

