import typeToReducer from 'type-to-reducer';

import {
  STATE,
  TOWNSHIP,
  SPECIALITY,
  RESET,
  STATE_DEFAULT,
  TOWNSHIP_DEFAULT,
  SPECIALITY_DEFAULT,
  DISCOUNTS_DEFAULT,
} from '../../constants/filters';

const initialState = {
  stateValue: STATE_DEFAULT,
  townShipValue: TOWNSHIP_DEFAULT,
  specialityValue: SPECIALITY_DEFAULT,
};

const setStateValue = (state, { payload }) => {
  const { category, value } = payload;
  return {
    ...state,
    stateValue: value,
    townShipValue: TOWNSHIP_DEFAULT,
    specialityValue:
      category === 'medica' ? SPECIALITY_DEFAULT : DISCOUNTS_DEFAULT,
  };
};

const setTownShipValue = (state, { payload }) => {
  const { category, value } = payload;
  return {
    ...state,
    townShipValue: value,
    specialityValue:
      category === 'medica' ? SPECIALITY_DEFAULT : DISCOUNTS_DEFAULT,
  };
};

const setSpecialityValue = (state, { payload }) => ({
  ...state,
  specialityValue: payload,
});

const reset = (state, { payload }) => ({
  ...state,
  stateValue: STATE_DEFAULT,
  townShipValue: TOWNSHIP_DEFAULT,
  specialityValue:
    payload.category === 'medica' ? SPECIALITY_DEFAULT : DISCOUNTS_DEFAULT,
});

const reducers = {
  [STATE]: setStateValue,
  [TOWNSHIP]: setTownShipValue,
  [SPECIALITY]: setSpecialityValue,
  [RESET]: reset,
};

export default typeToReducer(reducers, initialState);
