export const STATE = 'FILTERS/STATE';
export const TOWNSHIP = 'FILTERS/TOWNSHIP';
export const SPECIALITY = 'FILTERS/SPECIALITY';
export const RESET = 'FILTERS/RESET';

export const STATE_DEFAULT = {
  value: 0,
  label: 'Estado',
};

export const TOWNSHIP_DEFAULT = {
  value: 0,
  label: 'Municipio / Delegación',
};

export const SPECIALITY_DEFAULT = {
  value: 0,
  label: 'Buscar médico o expecialista',
};

export const DISCOUNTS_DEFAULT = {
  value: 0,
  label: 'Buscar descuento',
};
