import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import './TDConsentido.scss';
import InputSelect from '../../components/InputSelect';

import {
  getStatesTDC,
  getTownShipsByStateTDC,
  getSpecialitiesByLocationTDC,
} from '../../actions/catalog';
import {
  setStateValue,
  setTownShipValue,
  setSpecialityValue,
  reset,
} from '../../actions/filters';
import { getTDCDiscounts, resetDiscountsRM } from '../../actions/discounts';

import agenciaViajes from '../../assets/images/tdconsentido/agenciaviajes-hoteles.jpg';
import autos from '../../assets/images/tdconsentido/autos.jpg';
import entretenimiento from '../../assets/images/tdconsentido/entretenimiento.jpg';
import funeraria from '../../assets/images/tdconsentido/funeraria.jpg';
import gymDeportes from '../../assets/images/tdconsentido/gym-deportes.jpg';
import hogar from '../../assets/images/tdconsentido/hogar.jpg';
import mascotas from '../../assets/images/tdconsentido/mascotas.jpg';
import otrosServicios from '../../assets/images/tdconsentido/otros-servicios.jpg';
import restaurantes from '../../assets/images/tdconsentido/restaurantes.jpg';
import belleza from '../../assets/images/tdconsentido/spa-belleza-depilacion.jpg';

const TDConsentido = () => {
  const history = useHistory();
  const {
    states: { isLoading: isLoadingStates, data: states },
    townShips: { isLoading: isLoadingTownShips, data: townShips },
    specialities: { isLoading: isLoadingSpecialities, data: specialities },
  } = useSelector((state) => state.catalog);
  const { stateValue, townShipValue, specialityValue } = useSelector(
    (state) => state.filters
  );
  const { TDC, isLoading, specialityResults } = useSelector(
    (state) => state.discounts
  );

  const dispatch = useDispatch();

  const handleGetStatesTDC = () => {
    dispatch(getStatesTDC());
    dispatch(resetDiscountsRM());
  };

  const handleStateChange = (select) => {
    dispatch(setStateValue({ value: select, category: 'descuento' }));
    dispatch(getTownShipsByStateTDC(select.value));
  };

  const handleTownShipChange = (select) => {
    dispatch(setTownShipValue({ value: select, category: 'descuento' }));
    dispatch(getSpecialitiesByLocationTDC(stateValue.value, select.value));
  };

  const handleSpecialityChange = (select) => {
    dispatch(setSpecialityValue(select));
  };

  const handleSubmit = async () => {
    dispatch(
      getTDCDiscounts(
        stateValue.value,
        townShipValue.value,
        specialityValue.value,
        specialityValue.label
      )
    );
  };

  const getImage = (specialityResults) => {
    switch (specialityResults) {
      case 'AGENCIA DE VIAJES':
      case 'HOTELES':
        return agenciaViajes;
      case 'ALBERCA':
      case 'CLUB DEPORTIVO':
      case 'GIMNASIO':
        return gymDeportes;
      case 'CENTRO DE DEPILACION':
      case 'ESTETICA':
      case 'SPA Y BELLEZA':
      case 'SENTIDO SALUDABLE':
        return belleza;
      case 'ENTRETENIMIENTO':
        return entretenimiento;
      case 'FUNERARIA':
        return funeraria;
      case 'HOGAR':
        return hogar;
      case 'HOTEL':
      case 'RESTAURANTES':
        return restaurantes;
      case 'MASCOTAS':
        return mascotas;
      case 'RENTA DE AUTOS':
      case 'SERVICIO AUTOMOTRIZ':
        return autos;
      case 'SERVICIOS VARIOS':
        return otrosServicios;
      default:
        return otrosServicios;
    }
  };

  useEffect(() => {
    handleGetStatesTDC();
    return () => {
      dispatch(reset({ category: 'medica' }));
    };
  }, []);

  const imagen = getImage(specialityResults);
  return (
    <div className="contenedor-red-medica">
      <div className="contenedor_top">
        <h3>Busca de nuevo en los descuentos de TDConsentido</h3>
        <p>Define tu búsqueda para encontrar los descuentos</p>
      </div>
      <div className="filtro">
        <div className="red_medica">
          <div className={'form'}>
            <div className="estado">
              <InputSelect
                value={stateValue}
                options={states}
                disabled={isLoadingStates}
                onChange={handleStateChange}
              />
            </div>
            <div className="municipio">
              <InputSelect
                value={townShipValue}
                options={townShips}
                disabled={isLoadingTownShips}
                onChange={handleTownShipChange}
              />
            </div>
            <div className="medico">
              <InputSelect
                value={specialityValue}
                options={specialities}
                disabled={isLoadingSpecialities}
                onChange={handleSpecialityChange}
              />
            </div>
            <button onClick={handleSubmit} disabled={isLoading}>
              buscar
            </button>
          </div>
        </div>
      </div>
      <div className="contenedor_resultados">
        <div className="resultados">
          {TDC && TDC.length > 0 && (
            <>
              <h2>
                RESULTADOS <span>“{specialityResults}”</span>
              </h2>
              <ul className="grid">
                {TDC.map((discount, key) => (
                  <li key={key} className="r_item">
                    <div className="img">
                      <img
                        className={'img'}
                        src={imagen}
                        alt={specialityResults}
                      />
                    </div>
                    <div className="info">
                      <h5>{discount.nomComercial}</h5>
                      <p></p>
                      <p className="tipo">{discount.nomEspecialidad}</p>
                      <div className="datos_personales">
                        <p className="ubicacion">{`${discount.nomDireccion}, ${discount.nomColonia}, ${discount.nomMunicipio}, ${discount.nomEstado}, ${discount.cp}`}</p>
                        <p className="phone">{discount.telefonos}</p>
                        <p>{discount.pagWeb}</p>
                        <p className="restricciones">{discount.descuento}</p>
                        <p className="restricciones">
                          {discount.restricciones}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        {
          <div className="busqueda">
            <h3>BÚSQUEDAS</h3>
            <ul className="secciones">
              <li>
                <button onClick={() => history.push('red-medica')}>
                  Red Médica
                </button>
              </li>
              <li>
                <button className="active">
                  <span>Descuentos</span> TDConsentido
                </button>
              </li>
            </ul>
            <div className="b_seccion">
              <h3>
                <span>-</span> BUSCADO EN TDConsentido
              </h3>
              <ul className="rb">
                {specialities.map((speciality, key) => (
                  <li key={key}>
                    <button>•{speciality.label}</button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default TDConsentido;
