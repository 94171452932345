import { getItem } from '../utils/storage';

export const handleApiSuccess = (result) => {
  if (result && result.data && result.status === 200 ) {
    return (result.data && result.data.$values ? result.data.$values : result);
  } else {
    return Promise.reject('error');
  }
}

export const tokenInterceptor = (config) => {
  const accessToken = getItem('token');
  const newConfig = { ...config };
  if (accessToken) {
    newConfig.headers.Authorization = `Bearer ${accessToken}`;
  }
  return newConfig;
};


export const headerInterceptor = (config) => (
  {
    ...config,
    headers: {
      ...config.headers,
    },
  });

export const errorInterceptor = (error) => {
  if (error?.response?.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject('error');
};

export const successInterceptor = (response) => (
  { ...response }
);
