import typeToReducer from 'type-to-reducer';

import {
  GET_PROVIDERS_BY_TOWNSHIP_RM,
  GET_PROVIDERS_BY_TOWNSHIP_TDC,
  RESET_DISCOUNTS_RM,
  RESET_DISCOUNTS_TDC,
} from '../../constants/discounts';

const initialState = {
  redMedica: [],
  TDC: [],
  isLoading: false,
};

const getMedicalHomeDiscounts = (state, { payload }) => {
  return {
    ...state,
    specialityResults: payload.specialitySelectedLabel,
    isLoading: false,
    redMedica: payload || [],
  };
};

const getTDCDiscounts = (state, { payload }) => {
  return {
    ...state,
    specialityResults: payload.specialitySelectedLabel,
    isLoading: false,
    TDC: payload || [],
  };
};

const resetDiscountsRM = (state) => ({
  ...state,
  redMedica: [],
});

const resetDiscountsTDC = (state) => ({
  ...state,
  TDC: [],
});

const reducers = {
  [GET_PROVIDERS_BY_TOWNSHIP_RM]: {
    PENDING: (state) => ({ ...state, isLoading: true, redMedica: [] }),
    FULFILLED: getMedicalHomeDiscounts,
    REJECTED: (state) => ({ ...state, isLoading: false, redMedica: [] }),
  },
  [GET_PROVIDERS_BY_TOWNSHIP_TDC]: {
    PENDING: (state) => ({ ...state, isLoading: true, TDC: [] }),
    FULFILLED: getTDCDiscounts,
    REJECTED: (state) => ({ ...state, isLoading: false, TDC: [] }),
  },
  [RESET_DISCOUNTS_RM]: resetDiscountsRM,
  [RESET_DISCOUNTS_TDC]: resetDiscountsTDC,
};

export default typeToReducer(reducers, initialState);
