import { handleApiSuccess } from './utils';
import { setToken, deleteItem } from '../utils/storage';

export function makeAuthentication(instance) {

  const verifyLogin = ( membershipNumber ) => instance
    .get(`security/GetInfoMembresiaVrim?membresia=${membershipNumber}`)
    .then(handleApiSuccess)
    .then((result) => {
      return result;
    }).catch(error =>{
      return error;
    });

  const login = (user, password) => instance
    .post('Security/PostLogin?applicationId=2', { user, password })
    .then(handleApiSuccess)
    .then((result) => {
      if(result.isSuccess){
        setToken(result.data.token);
      }
      return result;
    }).catch(error =>{
      return error;
    });

  const logout = () => instance
    .post('security/users/logout', {})
    .then(handleApiSuccess)
    .finally((result) => {
      deleteItem('token');
      return result;
    });

  return {
    verifyLogin,
    login,
    logout,
  };
}
export default makeAuthentication;
