import axios from 'axios';
import delayAdapterEnhancer from 'axios-delay';
import { makeAuthentication } from './authentication';
import { makeCatalog } from './catalog';
import { makeDiscounts } from './discounts';
import { deleteItem } from '../utils/storage';
import {
  tokenInterceptor,
  headerInterceptor,
  successInterceptor,
  errorInterceptor,
} from './utils';

const { REACT_APP_URL } = process.env;
const instance = axios.create({
  adapter: delayAdapterEnhancer(axios.defaults.adapter),
  baseURL: REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(tokenInterceptor);
instance.interceptors.request.use(headerInterceptor);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      deleteItem('token');
    } else {
      return Promise.reject(error);
    }
  }
);

instance.interceptors.response.use(successInterceptor, errorInterceptor);

export const authentication = makeAuthentication(instance);
export const catalog = makeCatalog(instance);
export const discounts = makeDiscounts(instance);
