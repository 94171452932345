import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import to from 'await-to-js';

import InputSelect from '../../components/InputSelect';
import phone from '../../assets/images/icons/phone.svg';
import appStore from '../../assets/images/icons/appstore.png';
import playStore from '../../assets/images/icons/playstore.png';

import {
  getStatesRM,
  getTownShipsByStateRM,
  getSpecialitiesByLocationRM,
  getStatesTDC,
  getTownShipsByStateTDC,
  getSpecialitiesByLocationTDC,
} from '../../actions/catalog';
import {
  setStateValue,
  setTownShipValue,
  setSpecialityValue,
  reset,
} from '../../actions/filters';
import {
  getMedicalHomeDiscounts,
  getTDCDiscounts,
} from '../../actions/discounts';
import './Busqueda.scss';

const tabs = [
  {
    name: 'red médica',
    key: 'medica',
    textInfo:
      'Define tu búsqueda para encontrar el médico o el especialista que necesitas',
    filterClasses: {
      container: 'red_medica',
    },
    options: {
      states: true,
      township: true,
      specialty: true,
    },
  },
  {
    name: 'descuentos tdconsentido',
    key: 'descuentos',
    textInfo: 'Define tu búsqueda para encontrar el descuento que quieras',
    filterClasses: {
      container: 'red_medica',
    },
    options: {
      states: true,
      township: true,
      specialty: true,
    },
  },
  /*{
    name: 'promociones',
    key: 'promociones',
    filterClasses: {
      container: 'promociones_td',
    },
    textInfo: 'Selecciona el estado en el que quieras consultar las promociones',
    options: {
      states: true
    }
  }  */
];

const Busqueda = () => {
  const {
    states: { isLoading: isLoadingStates, data: states },
    townShips: { isLoading: isLoadingTownShips, data: townShips },
    specialities: { isLoading: isLoadingSpecialities, data: specialities },
  } = useSelector((state) => state.catalog);

  const { isLoading } = useSelector((state) => state.discounts);

  const history = useHistory();

  const { stateValue, townShipValue, specialityValue } = useSelector(
    (state) => state.filters
  );

  const [categorySelected, setCategory] = useState(tabs[0]);
  const dispatch = useDispatch();

  const handleStateChange = (select) => {
    dispatch(setStateValue({ value: select, category: categorySelected.key }));
    if (categorySelected.key === 'medica') {
      dispatch(getTownShipsByStateRM(select.value));
    } else {
      dispatch(getTownShipsByStateTDC(select.value));
    }
  };

  const handleTownShipChange = (select) => {
    dispatch(
      setTownShipValue({ value: select, category: categorySelected.key })
    );
    if (categorySelected.key === 'medica') {
      dispatch(getSpecialitiesByLocationRM(stateValue.value, select.value));
    } else {
      dispatch(getSpecialitiesByLocationTDC(stateValue.value, select.value));
    }
  };

  const handleSpecialityChange = (select) => {
    dispatch(setSpecialityValue(select));
  };

  const handleSubmit = async () => {
    switch (categorySelected.key) {
      case 'medica':
        const [err] = await to(
          dispatch(
            getMedicalHomeDiscounts(
              stateValue.value,
              townShipValue.value,
              specialityValue.value,
              specialityValue.label
            )
          )
        );
        if (err === null) {
          history.push('/red-medica');
        }
        break;
      case 'descuentos':
        const [er] = await to(
          dispatch(
            getTDCDiscounts(
              stateValue.value,
              townShipValue.value,
              specialityValue.value,
              specialityValue.label
            )
          )
        );
        if (er === null) {
          history.push('/td-consentido');
        }
        break;
      case 'promociones':
        history.push('/promociones');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (categorySelected.key === 'medica') {
      dispatch(getStatesRM());
    } else {
      dispatch(getStatesTDC());
    }
    dispatch(reset({ category: categorySelected.key }));
  }, [categorySelected]);

  return (
    <div className="contenedor">
      <div className="contenedor_top">
        <h3>¿Qué necesitas buscar?</h3>
        <p>Escoge la opción que más se ajuste a tus necesidades</p>
      </div>
      <div className="filtros">
        <div className="type">
          {tabs.map((tab) => (
            <div
              key={tab.key}
              className={`${tab.key} ${
                categorySelected.key === tab.key ? 'active' : ''
              }`}
              onClick={(_) => setCategory(tab)}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <div className={categorySelected.filterClasses.container}>
          <p>{categorySelected.textInfo}</p>
          <div className="form">
            {categorySelected.options.discounts && (
              <div className="idescuentos">
                <input type="" name="" placeholder="Buscar Descuentos" />
              </div>
            )}
            {categorySelected.options.states && (
              <div className="estado">
                <InputSelect
                  value={stateValue}
                  options={states}
                  disabled={isLoadingStates}
                  onChange={handleStateChange}
                />
              </div>
            )}
            {categorySelected.options.township && (
              <div className="municipio">
                <InputSelect
                  value={townShipValue}
                  options={townShips}
                  disabled={isLoadingTownShips}
                  onChange={handleTownShipChange}
                />
              </div>
            )}
            {categorySelected.options.specialty && (
              <div className="medico">
                <InputSelect
                  value={specialityValue}
                  options={specialities}
                  disabled={isLoadingSpecialities}
                  onChange={handleSpecialityChange}
                />
              </div>
            )}
            {categorySelected.options.postalCode && (
              <div className="codigo_postal">
                <input type="text" placeholder="Código Postal" />
              </div>
            )}
            {categorySelected.options.category && (
              <div className="categoria">
                <input type="text" name="" placeholder="Categoría" />
              </div>
            )}
            <button disabled={isLoading} onClick={handleSubmit}>
              buscar
            </button>
          </div>
        </div>
      </div>
      <div className="contenedor_informacion">
        <div className="phone">
          <img src={phone} alt="phone" />
        </div>
        <div className="informacion">
          <h2>Descarga la APP</h2>
          <p>Descarga la APP MedicallHome.</p>
          <p>Podrás presentar tu Membresía Digital desde tu celular.</p>
          <p>
            Tendrás un botón de emergencias médicas, de ambulancia y de doctor a
            domicilio.
          </p>
          <p>Accederás a promociones y descuentos especiales en tiempo real.</p>
          <p>
            Tendrás información actualizada de nuestras Membresías y sus
            beneficios.
          </p>
          <p>Llamada a médicos certificados.</p>
          <p>Envío de ambulancia.</p>
          <p>Doctor a domicilio.</p>
          <p>Promociones especiales.</p>
          <p>Códigos de descuentos.</p>
          <div className="img_movil">
            <img src={phone} alt="phone" />
          </div>
          <div className="tiendas">
            <img src={appStore} alt="" className="appstore" />
            <img src={playStore} alt="" className="playstore" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Busqueda;
