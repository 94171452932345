import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import './TDConsentido.scss';
import InputSelect from '../../components/InputSelect';

import {
  getStatesRM,
  getTownShipsByStateRM,
  getSpecialitiesByLocationRM,
} from '../../actions/catalog';
import {
  setStateValue,
  setTownShipValue,
  setSpecialityValue,
  reset,
} from '../../actions/filters';
import {
  getMedicalHomeDiscounts,
  resetDiscountsTDC,
} from '../../actions/discounts';
import doctores from '../../assets/images/redmedica/doctores1.png';

const RedMedica = () => {
  const history = useHistory();
  const {
    states: { isLoading: isLoadingStates, data: states },
    townShips: { isLoading: isLoadingTownShips, data: townShips },
    specialities: { isLoading: isLoadingSpecialities, data: specialities },
  } = useSelector((state) => state.catalog);
  const { stateValue, townShipValue, specialityValue } = useSelector(
    (state) => state.filters
  );
  const { redMedica, isLoading, specialityResults } = useSelector(
    (state) => state.discounts
  );

  const dispatch = useDispatch();

  const handleGetStatesRM = () => {
    dispatch(getStatesRM());
    dispatch(resetDiscountsTDC());
  };

  const handleStateChange = (select) => {
    dispatch(setStateValue({ value: select, category: 'medica' }));
    dispatch(getTownShipsByStateRM(select.value));
  };

  const handleTownShipChange = (select) => {
    dispatch(setTownShipValue({ value: select, category: 'medica' }));
    dispatch(getSpecialitiesByLocationRM(stateValue.value, select.value));
  };

  const handleSpecialityChange = (select) => {
    dispatch(setSpecialityValue(select));
  };

  const handleSubmit = async () => {
    dispatch(
      getMedicalHomeDiscounts(
        stateValue.value,
        townShipValue.value,
        specialityValue.value,
        specialityValue.label
      )
    );
  };

  useEffect(() => {
    handleGetStatesRM();
    return () => {
      dispatch(reset({ category: 'descuentos' }));
    };
  }, []);

  return (
    <div className="contenedor-red-medica">
      <div className="contenedor_top">
        <h3>Busca de nuevo en la Red Médica</h3>
        <p>
          Define tu búsqueda para encontrar el médico o el especialista que
          necesitas
        </p>
      </div>
      <div className="filtro">
        <div className="red_medica">
          <div className={'form'}>
            <div className="estado">
              <InputSelect
                value={stateValue}
                options={states}
                disabled={isLoadingStates}
                onChange={handleStateChange}
              />
            </div>
            <div className="municipio">
              <InputSelect
                value={townShipValue}
                options={townShips}
                disabled={isLoadingTownShips}
                onChange={handleTownShipChange}
              />
            </div>
            <div className="medico">
              <InputSelect
                value={specialityValue}
                options={specialities}
                disabled={isLoadingSpecialities}
                onChange={handleSpecialityChange}
              />
            </div>
            <button onClick={handleSubmit} disabled={isLoading}>
              buscar
            </button>
          </div>
        </div>
      </div>
      <div className="contenedor_resultados">
        <div className="resultados">
          {isLoading && <h2>BUSCANDO ...</h2>}
          {redMedica && redMedica.length > 0 && (
            <>
              <h2>
                RESULTADOS <span>“{specialityResults}”</span>
              </h2>
              <ul className="grid">
                {redMedica.map((discount, key) => (
                  <li key={key} className="r_item">
                    <div className="img">
                      <img src={doctores} alt="doctores" />
                    </div>
                    <div className="info">
                      <h5>{discount.nomComercial}</h5>
                      <p></p>
                      <p className="tipo">{discount.nomEspecialidad}</p>
                      <div className="datos_personales">
                        <p className="ubicacion">{`${discount.nomDireccion}, ${discount.nomColonia}, ${discount.nomMunicipio}, ${discount.nomEstado}, ${discount.cp}`}</p>
                        <p className="phone">{discount.telefono}</p>
                        {/*<p className="mail"><a href="#">correo electrónico</a></p>
                        <p className="distancia">20.00 Km</p>*/}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        {
          <div className="busqueda">
            <h3>BÚSQUEDAS</h3>
            <ul className="secciones">
              <li>
                <button className="active">Red Médica</button>
              </li>
              <li>
                <button onClick={() => history.push('td-consentido')}>
                  <span>Descuentos</span> TDConsentido
                </button>
              </li>
            </ul>
            <div className="b_seccion">
              <h3>
                <span>-</span> BUSCADO EN RED MÉDICA
              </h3>
              <ul className="rb">
                {specialities.map((speciality, key) => (
                  <li key={key}>
                    <button>•{speciality.label}</button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default RedMedica;
