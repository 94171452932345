import React from "react";
import {
    Route,
    Redirect
} from "react-router-dom";
import PrivateLayout from "../layouts/PrivateLayout";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                true ? (
                    <PrivateLayout>
                        <Component />
                    </PrivateLayout>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
