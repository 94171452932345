import React from 'react';
import medicalHome from '../assets/images/icons/medical-home-blue-red.svg';
import tec from '../assets/images/icons/tec.svg';
const Header = () => (
  <div className="top_bar">
    <div className="telefono"></div>
    <div className="header">
      <div className="logo">
        <a href="/">
          <img src={medicalHome} alt="medical-home" />
          <img src={tec} alt="tec" />
        </a>
      </div>
      <div className="leyenda">
        <p>Beneficios</p>
      </div>
    </div>
  </div>
);

export default Header;
