import React from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
const PrivateLayout = ({ children }) => {
    return (
        <div style={{ minHeight: "100vh" }}>
          <Header />
            {children}
          <Footer />
        </div>
    );
};
export default PrivateLayout;
