import React from 'react';
import medicalHome from '../assets/images/icons/medical-home-white.svg';

const Footer = () => (
  <>
    <div className="bottom_bar_marquee" />
    <div className="bottom_bar">
      <div className="logo">
        <a href="index.html">
          <img src={medicalHome} alt="medical-home-white" />
        </a>
      </div>
      <div className="bottom_info">
        <p>
          <a
            target="_blank"
            href="https://documentosmedicallhome.s3.amazonaws.com/compra.medicallHome/Terminos-y-condiciones-Membresias-MedicallHome-Plus.pdf"
          >
            Descargar Términos y condiciones
          </a>
          I
          <a
            target="_blank"
            href="https://documentosmedicallhome.s3.amazonaws.com/Aviso_de_Privacidad_MH.pdf"
          >
            Descargar Política de privacidad
          </a>
          I
          <a
            target="_blank"
            href="https://documentosmedicallhome.s3.amazonaws.com/Condiciones_Generales_Acc_Personales_F-461-10.pdf"
          >
            Descargar Términos Seguro de accidente
          </a>
        </p>
        <p>
          Medicall Home es una marca respaldada por Salud Interactiva S.A. de
          C.V.
        </p>
        <p>TODOS LOS DERECHOS RESERVADOS 2020</p>
      </div>
    </div>
  </>
);

export default Footer;
