import { handleApiSuccess } from './utils';

export function makeDiscounts(instance) {

  const getMedicalHomeDiscounts = ( stateValue, townShipValue, specialityValue, specialityLabel ) => instance
    .get(`MH_GetDatosProveedor_x_idEspecialidad_idMunicipio?idEstado=${stateValue}&idMunicipio=${townShipValue}&idEspecialidad=${specialityValue}`)
    .then(handleApiSuccess)
    .then((result) => {
      result.specialitySelectedLabel = specialityLabel;
      return result;
    }).catch(error =>{
      return error;
    });
  
  const getTDCDiscounts = ( stateValue, townShipValue, specialityValue, specialityLabel ) => instance
    .get(`TDC_GetDatosProveedor_x_idEspecialidad_idMunicipio?idEstado=${stateValue}&idMunicipio=${townShipValue}&idEspecialidad=${specialityValue}`)
    .then(handleApiSuccess)
    .then((result) => {
      result.specialitySelectedLabel = specialityLabel;
      return result;
    }).catch(error =>{
      return error;
    });

  return {
    getMedicalHomeDiscounts,
    getTDCDiscounts
  };
}
export default makeDiscounts;
