import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import PrivateRoute from './security/PrivateRouter';
import { store } from './store/configureStore';
import Login from './screens/public/Login';
import NotFound from './screens/public/NotFound';
import Busqueda from './screens/private/Busqueda';
import RedMedica from './screens/private/RedMedica';
import TDConsentido from './screens/private/TDConsentido';

const App = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/login" component={ Login } />
        <PrivateRoute exact path="/" component={ Busqueda } />
        <PrivateRoute exact path="/red-medica" component={ RedMedica } />
        <PrivateRoute exact path="/td-consentido" component={ TDConsentido } />
        <Route path="*" component={ NotFound } />
      </Switch>
    </Router>
  </Provider>
);

export default App;
