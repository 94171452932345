import {
  GET_STATES,
  GET_TOWNSHIPS,
  GET_SPECIALITIES,
} from '../../constants/catalog';
import { catalog } from '../../api';

export const getStatesRM = () => ({
  type: GET_STATES,
  payload: catalog.getStatesRM(),
});

export const getTownShipsByStateRM = (stateValue) => ({
  type: GET_TOWNSHIPS,
  payload: catalog.getTownShipsByStateRM(stateValue),
});

export const getSpecialitiesByLocationRM = (stateValue, townShipValue) => ({
  type: GET_SPECIALITIES,
  payload: catalog.getSpecialitiesByLocationRM(stateValue, townShipValue),
});

export const getStatesTDC = () => ({
  type: GET_STATES,
  payload: catalog.getStatesTDC(),
});

export const getTownShipsByStateTDC = (stateValue) => ({
  type: GET_TOWNSHIPS,
  payload: catalog.getTownShipsByStateTDC(stateValue),
});

export const getSpecialitiesByLocationTDC = (stateValue, townShipValue) => ({
  type: GET_SPECIALITIES,
  payload: catalog.getSpecialitiesByLocationTDC(stateValue, townShipValue),
});
