import typeToReducer from 'type-to-reducer';

import {
  GET_STATES,
  GET_TOWNSHIPS,
  GET_SPECIALITIES
} from '../../constants/catalog';

const initialState = {
  states: {
    data: [],
    isLoading: false,
  },
  townShips: {
    data: [],
    isLoading: false,
  },
  specialities: {
    data: [],
    isLoading: false
  }
};

const getStatesRM = (state, { payload }) => ({
  ...state,
  states: {
    isLoading: false,
    data: payload.map( st => ({ value: st.idEstado, label: st.nomEstado})),
  },
});

const getTownShips = (state, { payload }) => ({
  ...state,
  townShips: {
    isLoading: false,
    data: payload.map( town => ({ value: town.idMunicipio, label: town.nomMunicipio})),
  },
  specialities: {
    ...state.specialities,
    data: []
  }
});

const getSpecialities = (state, { payload }) => ({
  ...state,
  specialities: {
    isLoading: false,
    data: payload.map( speciality => ({ value: speciality.idEspecialidad, label: speciality.nomEspecialidad})),
  },
});

const reducers = {
  [GET_STATES]: {
    PENDING: (state) => ({ ...state, states: { ...state.states, isLoading: true, data: []}}),
    FULFILLED: getStatesRM,
    REJECTED: (state) => ({ ...state, states: { ...state.states, isLoading: false, data: []}}),
  },
  [GET_TOWNSHIPS]: {
    PENDING: (state) => ({ ...state, townShips: { ...state.townShips, isLoading: true, data: []}}),
    FULFILLED: getTownShips,
    REJECTED: (state) => ({ ...state, townShips: { ...state.townShips, isLoading: false, data: []}}),
  },
  [GET_SPECIALITIES]: {
    PENDING: (state) => ({ ...state, specialities: { ...state.specialities, isLoading: true, data: []}}),
    FULFILLED: getSpecialities,
    REJECTED: (state) => ({ ...state, specialities: { ...state.specialities, isLoading: false, data: []}}),
  },
};

export default typeToReducer(reducers, initialState);
