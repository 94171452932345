import {
  GET_PROVIDERS_BY_TOWNSHIP_RM,
  GET_PROVIDERS_BY_TOWNSHIP_TDC,
  RESET_DISCOUNTS_RM,
  RESET_DISCOUNTS_TDC
} from '../../constants/discounts';
import { discounts } from '../../api';

export const getMedicalHomeDiscounts = (stateValue, townshipValue, specialityValue, specialityLabel) => ({
  type: GET_PROVIDERS_BY_TOWNSHIP_RM,
  payload: discounts.getMedicalHomeDiscounts(stateValue, townshipValue, specialityValue, specialityLabel),
});

export const getTDCDiscounts = (stateValue, townshipValue, specialityValue, specialityLabel) => ({
  type: GET_PROVIDERS_BY_TOWNSHIP_TDC,
  payload: discounts.getTDCDiscounts(stateValue, townshipValue, specialityValue, specialityLabel),
});

export const resetDiscountsRM = () => ({
  type: RESET_DISCOUNTS_RM
});

export const resetDiscountsTDC = () => ({
  type: RESET_DISCOUNTS_TDC
});
