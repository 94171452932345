import React from 'react';
import Select from 'react-select';
import "./InputSelect.scss";
const InputSelect = ({value, options, onChange, disabled = false }) => {

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: disabled ? '#E0E0E0' : 'white' }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        marginTop: '0px',
        transition: 'all .4s',
        backgroundColor: isSelected
          ? '#001A70'
          : isFocused
          ? '#001A70'
          : '#f9f9f9',
        color: isSelected
          ? '#1ec6cf'
          : isFocused
          ? '#1ec6cf'
          : '#272727',
      };
    },
  };

  return (
    <Select
      classNamePrefix="react-select"
      value={value}
      options={options}
      styles={colourStyles}
      onChange={onChange}
      isDisabled={disabled}
      noOptionsMessage={() => 'No hay opciones'}
    />
  );
};

export default InputSelect;