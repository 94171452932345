import { STATE, TOWNSHIP, SPECIALITY, RESET } from '../../constants/filters';

export const setStateValue = (value) => ({
  type: STATE,
  payload: value,
});

export const setTownShipValue = (value) => ({
  type: TOWNSHIP,
  payload: value,
});

export const setSpecialityValue = (value) => ({
  type: SPECIALITY,
  payload: value,
});

export const reset = (value) => ({
  type: RESET,
  payload: value,
});
